<template>
  <div class="add-book">
    <p>
      Add a new book using the form below. You can save your progress as a draft
      and continue editing later. Once you have completed and checked all the
      sections, please save and then submit for publishing.
    </p>
    <BookForm />
  </div>
</template>

<script>
import BookForm from "@/components/book-form/book-form";

export default {
  name: "add-book",
  components: {
    BookForm
  },
  data() {
    return {};
  }
};
</script>

<style></style>
